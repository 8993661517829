import { useContext, useState, useEffect } from 'react';
import Button from '../components/Button';
import {
  SwitchHorizontalIcon,
  ClockIcon,
  PlusCircleIcon,
} from '@heroicons/react/outline';

import { useNavigate } from 'react-router-dom';

import UserContext from '../context/userContext';
import { googleLogout, imports } from '../api';

function Dashboard() {
  const [user, setUser] = useContext(UserContext);

  const [sheets, setSheets] = useState([]);

  let navigate = useNavigate();

  const disconnectHandler = () => {
    googleLogout().then((response) => {
      navigate('/onboarding');
    });
  };

  // useEffect(() => {
  //   imports().then((response) => {
  //     // console.log(response);
  //   });
  // }, []);

  return (
    <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-6 xl:px-16 ">
      <div className="flex gap-20">
        <img
          className="hidden xl:block"
          src={require('../images/welcome-img.svg').default}
          alt=""
        />

        <div className="space-y-6 w-full">
          <h1 className="text-2xl font-medium">Welcome back</h1>

          {/* User card */}
          <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-6">
            <div className="flex flex-col lg:flex-row items-center gap-4">
              <img
                className="flex-shrink-0 w-20 h-20 rounded-full"
                src={user?.google_picture}
                alt=""
              />

              <div className="flex flex-col md:flex-row items-center xl:items-end justify-between w-full gap-4">
                <div>
                  <h4 className="text-xl font-semibold">
                    Associated Google Account:
                  </h4>
                  <p>Full Name: {user?.name}</p>
                  <p>Email: {user?.google_account_email}</p>
                </div>
                <div>
                  <div className="rounded-full bg-green-100 flex justify-center items-center p-1 space-x-1">
                    <SwitchHorizontalIcon className="w-4 h-4 text-[#09C157]" />
                    <span className="text-sm font-medium text-[#09C157]">
                      Connected
                    </span>
                  </div>
                  <Button
                    variant="dark"
                    size="sm"
                    className="mt-2"
                    onClick={disconnectHandler}
                  >
                    Disconnect
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* Sheet card */}
          <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-6">
            <div className="flex flex-col lg:flex-row items-center gap-10">
              <figure className="rounded-md overflow-hidden shadow">
                <div className="bg-gray-200 p-2 flex-shrink-0">
                  <img src={require('../images/sheet.svg').default} alt="" />
                </div>
                <figcaption className="text-center p-3">
                  {/* <p className="font-medium">G Sheets name</p> */}
                </figcaption>
              </figure>

              <div className="flex flex-col md:flex-row lg:items-end justify-between w-full gap-4">
                <div className="max-w-sm">
                  <h4 className="text-xl font-semibold">Linked spreadsheet</h4>
                  <p className="text-gray-500 text-sm mt-1">
                    Click "Open Google Sheet" to access your linked sheet in new tab.
                  </p>
                  {/* <p className="text-gray-500 mt-2 text-sm flex gap-1 items-center">
                    <ClockIcon className="w-4 h-4" />
                    <span>Last sync: today, 2pm</span>
                  </p> */}

                  {/* <Button className="mt-5 " size="sm">
                    Pause Sync
                  </Button> */}
                </div>
                <div>
                  <a
                    href={`https://docs.google.com/spreadsheets/d/${user.google_sheets_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="dark"
                    size="sm"
                    className="mt-2 flex-shrink-0 button dark"
                  >
                    Open Google sheet
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <Button size="sm" onClick={() => navigate('/onboarding')}>
            <PlusCircleIcon className="w-4 h-4" />
            <span>Add Google Sheet</span>
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
