function OnBoardingCard({ img, title, subtitle }) {
  return (
    <figure className="text-center">
      <div className="flex items-center lg:min-h-[100px]">
        <img className="mx-auto" src={img} alt="" />
      </div>

      <figcaption className="mt-5">
        <h4 className="text-xl text-gray-900 font-medium">{title}</h4>
        <p className="mt-2 text-gray-500">{subtitle}</p>
      </figcaption>
    </figure>
  );
}

export default OnBoardingCard;
