import { useState, useEffect } from 'react';
import {
  getParamsFromUrlString,
  isEmpty,
  saveStorage,
  getStorage,
} from '../utils';
import { useNavigate } from 'react-router-dom';
// import Loader from '../components/Loader';

import { getShopifyLink, getShopifyToken } from '../api';
import Loader from '../components/Loader';

function Auth() {
  const [message, setMessage] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    const shop = getParamsFromUrlString('shop');
    const code = getParamsFromUrlString('code');

    // Return to the homepage if we have token
    if (!isEmpty(getStorage('token'))) return navigate('/');

    // Break if the shop name is empty
    if (isEmpty(shop)) return setMessage('Shop name is empty!');

    // Get link if the user don't have a token
    if (isEmpty(code)) {
      getShopifyLink(shop).then((response) => {
        window.location.href = response.data.link;
        return;
      });
    }

    // Get token if we have codeParam
    if (!isEmpty(code)) {
      getShopifyToken({ code, shop_name: shop }).then((response) => {
        saveStorage('token', response.data.token);
        navigate('/');
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const template = message ? (
    <div className="h-screen flex items-center justify-center">{message}</div>
  ) : (
    <Loader />
  );

  return template;
}

export default Auth;
