import React, { useContext, useEffect, useState } from 'react';
import Button from '../components/Button';
// import { PlusIcon } from '@heroicons/react/outline';
import Notice from '../components/Notice';
import UserContext from '../context/userContext';
import { getSheetHeaders, saveGoogleColumns, saveGoogleSheets } from '../api';
import Alert from '../components/Alert';
import labels from '../labels.json';

function Mapping() {
  const [user] = useContext(UserContext);
  const [columns2, setColumns2] = useState([]);
  // const [labels, setLabels] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');

  useEffect(() => {
    // console.log(user);
    let arr = user.google_sheet_column_association;

    let arr2 = [];
    for (const col in arr) {
      arr2.push({ name: arr[col].header, value: col, column: arr[col].column });
    }

    arr2 = arr2.sort((a, b) => a.column - b.column);
    setColumns2(arr2);
  }, []);

  const refreshSelectListHandler = () => {
    getSheetHeaders(user.google_sheets_id, user.google_sheets_tab).then(
      (response) => {
        const arr = response.data.map((i, idx) => ({
          name: i,
          value: Object.keys(user.google_sheet_column_association)[idx],
          column: idx,
        }));
        setColumns2(arr);

        setTextAlert('Refreshed!');
        setTypeAlert('success');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }
    );
  };

  const handleFormChange = (index, event) => {
    let data = [...columns2];
    data[index].value = event.target.value;
    setColumns2(data);
  };

  const saveHandler = () => {
    setLoading(true);
    let associations = {};
    for (let i = 0; i < columns2.length; i++) {
      Object.assign(associations, {
        [columns2[i].value]: {
          header: columns2[i].name,
          column: columns2[i].column,
        },
      });
    }

    saveGoogleSheets(user.google_sheets_id, user.google_sheets_tab).then(
      (response) => {
        if (response.status === 200) {
          saveGoogleColumns(associations)
            .then(() => {
              setTextAlert('Saved!');
              setShowAlert(true);
              setLoading(false);
              setTypeAlert('success');

              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            })
            .catch(function () {
              setTextAlert('Order id column not provided');
              setShowAlert(true);
              setTypeAlert('error');
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            })
            .then(() => {
              setLoading(false);
            });
        }
      }
    );
  };

  return (
    <>
      <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-6 xl:px-16">
        <div className="flex flex-col lg:flex-row gap-5 items-center md:items-end justify-between">
          <div className="max-w-lg">
            <h1 className="text-2xl font-medium">Data Mapping</h1>
            <p className="text-gray-500 mt-1">
              Add a new field mapping for any changes you want to make and hit
              save button. If you could not find a field you need please don't
              hesitate to contact us to add it for you .
            </p>
          </div>

          {/* <Button className="space-x-2" variant="success">
          <PlusIcon className="w-5 h-5" />
          <span>Add new field mapping</span>
        </Button> */}
        </div>

        <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-4 md:p-6 mt-6">
          <Notice>
            <p className="text-sm">
              <a href="/link" className="text-blue-500 hover:text-blue-600">
                (Notice : Order ID is obligatory)
              </a>
              &nbsp; Enter a value on the first row with data points you need
              and customize specific settings based on your store reporting
              method
            </p>
          </Notice>

          <div className="mt-8 space-y-6">
            {columns2 &&
              columns2.map((column, index) => (
                <div key={column.name}>
                  <label
                    htmlFor={column.name}
                    className="block font-medium text-gray-700"
                  >
                    {column.name}
                  </label>
                  <select
                    id={column.name}
                    className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    onChange={(event) => handleFormChange(index, event)}
                    value={column.value}
                  >
                    {labels &&
                      labels.map((label) => (
                        <option value={label.value} key={label.value}>
                          {label.label}
                        </option>
                      ))}
                  </select>
                </div>
              ))}

            {/* <div className="flex justify-center">
            <button
              type="button"
              className="text-blue-500 hover:text-blue-600 font-medium"
              onClick={refreshSelectListHandler}
            >
              Refresh List
            </button>
          </div> */}
          </div>

          <div className="flex justify-between gap-3 mt-6">
            <Button variant="outline" onClick={refreshSelectListHandler}>
              Refresh
            </Button>
            <Button onClick={saveHandler} loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <Alert show={showAlert} type={typeAlert}>
        {textAlert}
      </Alert>
    </>
  );
}

export default Mapping;
