import { createRoot } from 'react-dom/client';

import 'react-datepicker/dist/react-datepicker.css';
import './css/main.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import OnBoardingPage from './pages/OnBoarding';
import AppPage from './pages/App';
import Dashboard from './pages/Dashboard';
import Mapping from './pages/Mapping';
import BackInTime from './pages/BackInTime';
import Auth from './pages/Auth';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AppPage />}>
        <Route index element={<Dashboard />} />
        <Route path="/mapping" element={<Mapping />} />
        <Route path="/back-in-time" element={<BackInTime />} />
      </Route>
      <Route path="/onboarding" element={<OnBoardingPage />} />
      <Route path="/auth" element={<Auth />} />
    </Routes>
  </BrowserRouter>
);
