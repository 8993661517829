import { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

function Alert({ type = 'success', children, show }) {
  return createPortal(
    <Transition
      as={Fragment}
      show={show}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 translate-y-6"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 translate-y-6"
    >
      <div
        className={`fixed bottom-5 left-5 z-30 rounded-md shadow-lg p-4  flex items-center gap-2 ${
          type === 'success' && 'bg-green-100 text-green-700 '
        } ${type === 'error' && 'bg-red-100 text-red-700 '}`}
      >
        <div>
          {type === 'success' && (
            <CheckCircleIcon className="text-green-400 w-6" />
          )}

          {type === 'error' && (
            <ExclamationCircleIcon className="text-red-400 w-6" />
          )}
        </div>
        <p>{children}</p>
      </div>
    </Transition>,
    document.querySelector('body')
  );
}

export default Alert;
