import React from 'react';

function Notice({ children, className }) {
  return (
    <div
      className={`rounded-md border-l-8 p-3 bg-blue-100 border-blue-500 ${className}`}
    >
      {children}
    </div>
  );
}

export default Notice;
