import { useContext, useState } from 'react';
import DatePick from '../components/DatePick';
import Button from '../components/Button';
import { format } from 'date-fns';
import { importOrders } from '../api';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/userContext';

function BackInTime() {
  const [startDate, setStartDate] = useState(new Date());
  const [user, setUser] = useContext(UserContext);

  let navigate = useNavigate();

  const syncOrdersHandler = () => {
    const from = format(startDate, 'yyyy-MM-dd');
    const today = new Date();
    today.setDate(today.getDate() + 1);

    const to = format(today, 'yyyy-MM-dd');

    importOrders(from, to).then((response) => {
      let date = new Date();
      date.setTime(date.getTime() + 15 * 60 * 1000);
      localStorage.setItem('sync_interval', date);

      navigate('/');
    });
  };
  return (
    <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-6 xl:px-16 py-20 ">
      <div className="mx-auto max-w-sm text-center">
        <h4 className="text-xl font-medium">Back in time</h4>
        <img
          className="mx-auto w-16 h-16 mt-4"
          src={require('../images/back-in-time-icon.png')}
          alt=""
        />
        <p className="text-xs text-gray-400 mt-4">
          Sync Orders Already Fulfilled
        </p>
        <p className="text-sm text-gray-500 mt-4">
          By executing the ‘Back-In-Time’ feature you will be able to add past
          fulfilled orders to you sheet. To execute, choose a start date and
          click “Sync”
        </p>

        <div className="mt-4">
          <DatePick startDate={startDate} setStartDate={setStartDate} />
        </div>

        <Button
          className="mt-6 w-full"
          variant="success"
          onClick={syncOrdersHandler}
          disabled={user.syncDisabled}
        >
          Sync
        </Button>
      </div>
    </div>
  );
}

export default BackInTime;
