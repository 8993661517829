import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppCards from '../components/AppCards';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { getParamsFromUrlString, getStorage, isEmpty } from '../utils';
import { changePlan, changePlanCallBack, getUser } from '../api';
import UserContext from '../context/userContext';
import Loader from '../components/Loader';

function App() {
  const [user, setUser] = useState({});
  let navigate = useNavigate();
  useEffect(() => {
    const shopParam = getParamsFromUrlString('shop');

    // const codeParam = getParamsFromUrlString('code');

    if (isEmpty(getStorage('token')))
      return navigate(`/auth?shop=${shopParam}`);

    if (!isEmpty(getStorage('token'))) {
      getUser().then((response) =>
        setUser((prev) => ({ ...prev, ...response.data }))
      );
      // getUser().then((response) => console.log(response.data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isEmpty(user) &&
      user.hasOwnProperty('plan_name') &&
      isEmpty(user.google_account_email)
    ) {
      navigate('/onboarding');
    }

    const chargeParam = getParamsFromUrlString('charge_id');

    if (
      !isEmpty(user) &&
      !user.hasOwnProperty('plan_name') &&
      isEmpty(chargeParam)
    ) {
      changePlan().then((response) => {
        window.location.href = response.data.link;
      });
    }

    if (
      !isEmpty(chargeParam) &&
      !isEmpty(user) &&
      !user.hasOwnProperty('plan_name')
    ) {
      changePlanCallBack(chargeParam).then((response) => {
        getUser().then((response) =>
          setUser((prev) => ({ ...prev, ...response.data }))
        );
      });
    }
  }, [user]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (!isEmpty(localStorage.getItem('sync_interval'))) {
        const age = new Date(localStorage.getItem('sync_interval')).getTime();
        const now = new Date().getTime();

        if (now >= age) {
          localStorage.removeItem('sync_interval');
          setUser((prev) => ({
            ...prev,
            syncDisabled: false,
          }));
        } else {
          setUser((prev) => ({
            ...prev,
            syncDisabled: true,
          }));
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {!isEmpty(user) &&
      !isEmpty(user.google_account_email) &&
      user.hasOwnProperty('plan_name') ? (
        <div className="h-screen overflow-hidden bg-gray-50 ">
          <Navbar />

          <div className="pt-16 flex h-full relative">
            <Sidebar />
            <div className="w-full h-full overflow-auto flex flex-col p-4 md:p-6">
              <div className="flex-1">
                <Outlet />
              </div>

              <div className="mt-8">
                <AppCards />
                <div className="mt-6">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </UserContext.Provider>
  );
}

export default App;
