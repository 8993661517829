import { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
// import { HiOutlineCalendar, HiChevronDown } from 'react-icons/hi';
import { CalendarIcon } from '@heroicons/react/solid';

function DatePick({ startDate, setStartDate }) {
  // const [startDate, setStartDate] = useState(new Date());
  const CustomButton = forwardRef(({ value, onClick }, ref) => (
    <div className="datepicker-button" onClick={onClick} ref={ref}>
      <div className="flex-1 text-center flex items-center justify-center">
        <CalendarIcon className="w-6 h-6 text-[#707070] mr-2" />
        <span className="datepicker-button-text">{value}</span>
      </div>
    </div>
  ));

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      customInput={<CustomButton />}
      dateFormat="dd MMMM yyyy"
      popperClassName="datepicker"
    />
  );
}

export default DatePick;
