import axios from 'axios';
import { getStorage } from './utils';

const REACT_APP_MAIN_REDIRECT =
  process.env.REACT_APP_MAIN_REDIRECT || `${window.location.origin}/`;

const REACT_APP_SHOPIFY_AUTH_REDIRECT =
  process.env.REACT_APP_SHOPIFY_AUTH_REDIRECT ||
  `${window.location.origin}/auth`;

const REACT_APP_SHOPIFY_BILLING_REDIRECT =
  process.env.REACT_APP_SHOPIFY_BILLING_REDIRECT ||
  `${window.location.origin}/`;

const REACT_APP_GOOGLE_REDIRECT_PAGE =
  process.env.REACT_APP_GOOGLE_REDIRECT_PAGE ||
  `${window.location.origin}/onboarding`;

const API_ORIGIN = 'https://api.uptosheets.com';

const api = axios.create({
  baseURL: API_ORIGIN,
});

// Shopify requests
export const getShopifyLink = (shop) => {
  return api.get('signin/shopify', {
    params: {
      shop,
      redirect: REACT_APP_SHOPIFY_AUTH_REDIRECT,
    },
  });
};

export const getShopifyToken = (body) => {
  return api.post('auth/shopify', body);
};

export const getUser = () => {
  return api.get('/me', {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const changePlan = () => {
  return api.post(
    'change_plan',
    { plan_name: 'ONEPLAN', redirect: REACT_APP_MAIN_REDIRECT },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const changePlanCallBack = (charge_id) => {
  return api.get('payment', {
    params: {
      charge_id,
      redirect: REACT_APP_MAIN_REDIRECT,
    },
  });
};

// Google sign in
export const getGoogleLink = () => {
  return api.get('signin/google', {
    //params: {
    //  redirect: REACT_APP_GOOGLE_REDIRECT_PAGE,
    //},
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const getGoogleToken = (body) => {
  return api.post('auth/google', body, {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const googleLogout = () => {
  return api.post(
    'deactivate',
    {},
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const getSheetTabs = (id) => {
  return api.get('google_sheets/tabs', {
    params: {
      sheet_id: id.match(/\/d\/(.+)?\/edit/i)[1],
    },
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const getSheetHeaders = (id, tab) => {
  let localID;

  if (!id.match(/\/d\/(.+)?\/edit/i)) {
    localID = id;
  } else {
    localID = id.match(/\/d\/(.+)?\/edit/i)[1];
  }

  return api.get('google_sheets/headers', {
    params: {
      sheet_id: localID,
      tab,
    },
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const saveGoogleSheets = (id, tab) => {
  let localID;

  if (!id.match(/\/d\/(.+)?\/edit/i)) {
    localID = id;
  } else {
    localID = id.match(/\/d\/(.+)?\/edit/i)[1];
  }

  return api.post(
    'google_sheets/save',
    { id: localID, tab },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const saveGoogleColumns = (body) => {
  return api.post(
    'google_sheets/association',
    { association: { ...body } },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const importOrders = (from, to) => {
  return api.post(
    `google_sheets/import/orders`,
    {},
    {
      params: { from, to },
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const imports = () => {
  return api.get(`imports`, {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};
