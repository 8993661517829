import { NavLink } from 'react-router-dom';
import {
  HomeIcon,
  MapIcon,
  CalendarIcon,
  LockClosedIcon,
  PuzzleIcon,
  ArrowSmRightIcon,
} from '@heroicons/react/outline';

function Sidebar() {
  const sidebarHideHandler = () => {
    document.documentElement.classList.remove('sidebar-active');
  };

  return (
    <>
      <div className="sidebar-overlay" onClick={sidebarHideHandler}></div>
      <div className="sidebar">
        {/* Top */}
        <div className="space-y-2 flex-1">
          <NavLink to="/" className="sidebar-link" onClick={sidebarHideHandler}>
            <HomeIcon className="sidebar-icon" />
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            to="/mapping"
            className="sidebar-link"
            onClick={sidebarHideHandler}
          >
            <MapIcon className="sidebar-icon" />
            <span>Data mapping</span>
          </NavLink>
          <NavLink
            to="/back-in-time"
            className="sidebar-link"
            onClick={sidebarHideHandler}
          >
            <CalendarIcon className="sidebar-icon" />
            <span>Back in Time</span>
          </NavLink>
        </div>

        {/* Bottom */}
        <div className="space-y-2">
          <a
            className="write-review-button group mt-4"
            href="https://apps.shopify.com/uptosheets#modal-show=ReviewListingModal"
            target="_blank"
            rel="noreferrer"
          >
            <span>Write a review </span>
            <div className="write-review-icon">
              <img
                src={require('../images/write-review-img.svg').default}
                alt=""
              />
              <ArrowSmRightIcon className="w-0 group-hover:w-5 transition-all text-white flex-shrink-0 font-bold" />
            </div>
          </a>

          <a
            href="https://google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-link is-hover"
            onClick={sidebarHideHandler}
          >
            <PuzzleIcon className="sidebar-icon" />
            <span><a href="mailto:contact@partnerswire.com">Request a feature</a></span>
          </a>
          <a
            href="https://google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-link"
            onClick={sidebarHideHandler}
          >
            <LockClosedIcon className="sidebar-icon" />
            <span><a href="https://uptosheets.com/privacy.html">Privacy</a></span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
