import { Link } from 'react-router-dom';
import Logo from '../images/logo.svg';
import { MenuAlt2Icon } from '@heroicons/react/solid';
import ProfileDropDown from './ProfileDropDown';

function Navbar() {
  const sidebarHideHandler = () => {
    document.documentElement.classList.toggle('sidebar-active');
  };

  return (
    <div className="navbar">
      <button type="button" onClick={sidebarHideHandler} className="md:hidden">
        <MenuAlt2Icon className="w-6 h-6 text-white" />
      </button>
      <Link to="/">
        <img src={Logo} alt="UpToSheets" />
      </Link>

      <ProfileDropDown />
    </div>
  );
}

export default Navbar;
