import Logo from '../images/logo.svg';

function Loader() {
  return (
    <div className="h-screen flex items-center justify-center bg-[#273238]">
      <div className="animate-pulse">
        <img src={Logo} alt="Loading..." />
      </div>
    </div>
  );
}

export default Loader;
