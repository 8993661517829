import React from 'react';

function AppCard({ img, title, subtitle, text, link }) {
  return (
    <figure className=" bg-white border border-gray-200 rounded-lg p-4 h-full">
      <div className="flex items-start">
        <img className="flex-shrink-0 w-12 mr-3" src={img} alt="" />
        <div className="space-y-2">
          <h4 className="font-medium leading-none">{title}</h4>
          <p className="text-xs text-gray-400">{subtitle}</p>
        </div>
      </div>
      <figcaption className="mt-3">
        <div className="overflow-hidden max-h-[60px] flex-1">
          <p className="text-gray-500 text-sm">{text}</p>
        </div>

        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 block text-center bg-[#008060] text-white rounded-md font-medium text-sm leading-none p-2.5 hover:opacity-90 transition-opacity"
        >
          Try it free now
        </a>
      </figcaption>
    </figure>
  );
}

export default AppCard;
