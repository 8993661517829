import AppCard from './AppCard';

const cards = [
  {
    title: "Paltrack sync paypal tracking",
    subtitle: "TrackiPay is now Paltrack : submit tracking numbers to paypal",
    text: "Paltrack is a solution developed to sync fulfilled orders tracking information between your Shopify store and your PayPal account, and keep in sync paypal order status in real time and on autopilot..",
    link: "https://apps.shopify.com/paltrack",
    img: require("../images/paltrack.png"),
  },
  {
    title: "Reviewbit WhatsApp Reviews",
    subtitle: "Collect Photo Reviews via Whatsapp works w/ loox and all",
    text: "Collect customer reviews through WhatsApp automatically. Reviewbit lets you maximize social proof and customer trust without compromising your brand.",
    link: "https://platform.shoffi.app/v1/whatsapp-reviews/euxine-3MxdimYQ",
    img: require("../images/whatsappreviews.png"),
  },
  {
    title: "Incentivize — Cart Upsell",
    subtitle: "Increase revenue by upselling at the right moment. $250 free.",
    text: "Built by ex-Shopify employees: We know ecommerce, we know mobile matters. Let's get you started with $250 of free upselling.",
    link: "https://platform.shoffi.app/v1/incentivize-cart-upsell/euxine-QVPqhKM4k",
    img: require("../images/cartupsell.png"),
  },
  {
    title: "CVox ‑ Email & SMS Marketing",
    subtitle: "Lading Page Builder, Popups, Email & SMS Bump Marketing & more",
    text: "Plan, design, and build integrated customer experiences by leveraging personalized touchpoints towards delivering higher conversion rates, higher average order values (AOV), and increased customer loyalty.",
    link: "https://platform.shoffi.app/v1/customervox/euxine-hteeM3lm",
    img: require("../images/cvox.png"),
  },
];

function AppCards() {
  return (
    // <div className="border border-gray-200 shadow-sm rounded-lg bg-white p-6">
    <>
      <h3 className="text-lg font-medium">Recommended Shopify Apps</h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 mt-4 gap-4 md:gap-6">
        {cards.map((card, idx) => (
          <AppCard {...card} key={idx} />
        ))}
      </div>
    </>
  );
}

export default AppCards;
