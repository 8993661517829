import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

import UserContext from '../context/userContext';

import { ChevronDownIcon, CogIcon, LogoutIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProfileDropDown() {
  const [user] = useContext(UserContext);

  const logoutHandler = () => {
    sessionStorage.removeItem('token');
    window.location.href = '/';
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="inline-flex items-center space-x-1">
        <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
          <p className="text-sm font-semibold text-primary-200">
            {user.name && user.name.slice(0, 2).toUpperCase()}
          </p>
        </div>
        <ChevronDownIcon className="text-white w-4 h-4" />
      </Menu.Button>

      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={logoutHandler}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'px-4 py-2 text-sm space-x-2 flex items-center w-full'
                )}
              >
                <LogoutIcon className="text-gray-900 w-5 h-5" />
                <span>Logout</span>
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}

export default ProfileDropDown;
