import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../images/logo.svg';
import permissionScreen from '../images/permission-screen.svg';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import {
  ExclamationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';

import { RadioGroup } from '@headlessui/react';

import Button from '../components/Button';
import OnBoardingCard from '../components/OnBoardingCard';
import DatePick from '../components/DatePick';
import Notice from '../components/Notice';
import Alert from '../components/Alert';

import {
  getGoogleLink,
  getGoogleToken,
  getSheetHeaders,
  getSheetTabs,
  getUser,
  importOrders,
  saveGoogleColumns,
  saveGoogleSheets,
} from '../api';
import { getParamsFromUrlString, isEmpty } from '../utils';
import labels from '../labels.json';

const onBoardingCardsList = [
  {
    img: require('../images/connect-google.svg').default,
    title: '1. Connect your Google account',
    subtitle:
      'Click All Permissions to allow UpToSheets to fully connect to your account',
  },
  {
    img: require('../images/link-google.svg').default,
    title: '2. Link your Google sheet',
    subtitle:
      'Click All Permissions to allow UpToSheets to fully connect to your account',
  },
  {
    img: require('../images/shopify-sync.svg').default,
    title: '3. Start syncing your data',
    subtitle:
      'Click All Permissions to allow UpToSheets to fully connect to your account',
  },
];

const steps = [
  { name: 'Connect your google account', step: 0 },
  { name: 'Link your spreadsheet', step: 1 },
  { name: 'Set your spreadsheet tab', step: 2 },
  { name: 'Mapping your meta field', step: 3 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function OnBoarding() {
  const [page, setPage] = useState('start');
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({});
  const [sheetID, setSheetID] = useState('');

  const [sheetTabs, setSheetTabs] = useState([]);
  const [selected, setSelected] = useState(sheetTabs[0]);
  const [columns, setColumns] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(null);
  const [typeAlert, setTypeAlert] = useState('error');

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const codeParam = getParamsFromUrlString('code');
    if (!isEmpty(codeParam)) {
      setPage('step');
      getGoogleToken({ code: codeParam }).then((response) => {
        getUser().then((response) => {
          setUser(response.data);
        });
      });
    }
  }, []);

  useEffect(() => {
    getUser().then((response) => {
      setUser(response.data);
    });
  }, []);

  const googleConnectHandler = () => {
    getGoogleLink().then((response) => {
      window.location.href = response.data.link;
    });
  };

  const sheetIDHandler = () => {
    setLoading(true);
    getSheetTabs(sheetID)
      .then((response) => {
        setSheetTabs(response.data.tabs);
        setStep(2);
      })
      .catch(() => {
        setTextAlert('Fail to get sheet!');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const sheetTabsHandler = () => {
    setLoading(true);
    getSheetHeaders(sheetID, selected)
      .then((response) => {
        const arr = response.data.map((i, idx) => ({
          name: i,
          value: '',
          column: idx,
        }));
        setColumns(arr);
        setStep(3);
      })
      .catch(() => {
        setTextAlert('No headers!');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const finishHandler = () => {
    setLoading(true);
    let associations = {};
    for (let i = 0; i < columns.length; i++) {
      Object.assign(associations, {
        [columns[i].value]: {
          header: columns[i].name,
          column: columns[i].column,
        },
      });
    }

    saveGoogleSheets(sheetID, selected).then((response) => {
      if (response.status === 200) {
        saveGoogleColumns(associations)
          .then(() => {
            setPage('finish');
            setLoading(false);
          })
          .catch(function () {
            setTextAlert('Order id column not provided');
            setShowAlert(true);
            setTypeAlert('error');
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
          })
          .then(() => {
            setLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    setSelected(sheetTabs[0]);
  }, [sheetTabs]);

  const handleFormChange = (index, event) => {
    let data = [...columns];
    data[index].value = event.target.value;
    setColumns(data);
  };

  const refreshTabListHandler = () => {
    getSheetTabs(sheetID).then((response) => {
      setSheetTabs(response.data.tabs);
    });
  };

  const refreshSelectListHandler = () => {
    getSheetHeaders(sheetID, selected).then((response) => {
      const arr = response.data.map((i, idx) => ({
        name: i,
        value: '',
        column: idx,
      }));
      setColumns(arr);
    });
  };

  const syncOrdersHandler = () => {
    const from = format(startDate, 'yyyy-MM-dd');
    const today = new Date();
    today.setDate(today.getDate() + 1);

    const to = format(today, 'yyyy-MM-dd');

    importOrders(from, to).then((response) => {
      navigate('/');
    });
  };

  return (
    <>
      <div className="bg-gray-50 min-h-screen pt-24 pb-8 md:pt-32 md:pb-16 flex">
        <div className="fixed top-0 left-0 w-full text-center bg-dark py-3.5 z-20">
          <img className="inline-block" src={logo} alt="UpToSheets" />
        </div>

        <div className="container">
          {/* First page */}
          {page === 'start' && (
            <div className="card h-full py-16 md:px-20 flex flex-col justify-center items-center">
              {/* Cards */}
              <div className="grid lg:grid-cols-3 gap-16 md:gap-24">
                {onBoardingCardsList.map((item, index) => (
                  <OnBoardingCard key={index} {...item} />
                ))}
              </div>

              <Button
                variant="dark"
                className="mt-10"
                type="button"
                onClick={() => setPage('step')}
              >
                Get Started
              </Button>
            </div>
          )}

          {/* Second page */}
          {page === 'step' && (
            <div className="card">
              <div className="grid md:grid-cols-8 gap-6">
                <div className="md:col-span-2 pb-6 md:pb-0 md:pr-6 border-b md:border-b-0 md:border-r border-gray-200">
                  <img
                    src={require('../images/shopify-sheets.svg').default}
                    alt=""
                  />
                  <p className="mt-4">
                    Optimize your store management experience by instantly
                    syncing orders data between Google Sheets and Shopify Store
                  </p>

                  <ol role="list" className="space-y-6 mt-10">
                    {steps.map((s) => (
                      <li key={s.name}>
                        {s.step === step ? (
                          <div className="flex items-start ">
                            <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                              <CheckCircleIcon
                                className="h-full w-full text-indigo-600"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-3 text-sm font-medium text-indigo-600">
                              {s.name}
                            </span>
                          </div>
                        ) : s.step < step ? (
                          <div className="flex items-start" aria-current="s">
                            <span
                              className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                              aria-hidden="true"
                            >
                              <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                              <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                            </span>
                            <span className="ml-3 text-sm font-medium text-indigo-600">
                              {s.name}
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-start">
                            <div
                              className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                              aria-hidden="true"
                            >
                              <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                            </div>
                            <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                              {s.name}
                            </p>
                          </div>
                        )}
                      </li>
                    ))}
                  </ol>
                </div>

                <div className="md:col-span-6">
                  {step === 0 && (
                    <>
                      <h2 className="text-3xl font-medium">
                        Connect your Google account
                      </h2>

                      {isEmpty(user.google_account_email) ? (
                        <div className="border border-gray-200 rounded-md md:flex mt-10">
                          <div className="p-3 md:border-r border-gray-200 flex items-center">
                            <img
                              src={
                                require('../images/google-check.svg').default
                              }
                              alt=""
                            />
                          </div>
                          <div className="pt-0 md:pt-4 p-4 w-full md:flex items-center justify-between">
                            <div className="mb-3 md:mb-0 md:mr-3">
                              <h4 className="text-lg font-medium">
                                Connect your Google account
                              </h4>
                              <p className="text-sm text-gray-500 mt-1">
                                Click All Permissions to allow UpToSheets to
                                fully connect to your account
                              </p>
                            </div>

                            <Button onClick={googleConnectHandler}>
                              Connect
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="border border-gray-200 rounded-md md:flex mt-10">
                          <div className="p-3 md:border-r border-gray-200 flex items-center">
                            <img
                              src={
                                require('../images/google-check.svg').default
                              }
                              alt=""
                            />
                          </div>
                          <div className="pt-4 p-4 w-full flex items-center justify-between">
                            <div>
                              <div className="inline-flex items-center">
                                <div className="w-4 h-4 bg-blue-500 rounded-full ring-offset-2 ring-1 ring-blue-500 flex-shrink-0 mr-4"></div>
                                <p>{user.google_account_email}</p>
                              </div>
                            </div>

                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                          </div>
                        </div>
                      )}

                      <div className="bg-green-50 rounded-md p-4 md:p-6 inline-flex items-start mt-8">
                        <ExclamationCircleIcon className="text-green-500 w-8 flex-shrink-0" />
                        <div className="ml-4 md:ml-6">
                          <h4 className="font-medium">
                            Make sure you check the mark of permission to:
                          </h4>
                          <p className="text-sm text-gray-500 mt-1">
                            See, edit, create, and delete all your Google Sheets
                            spreadsheets.
                          </p>
                          <img className="mt-5" src={permissionScreen} alt="" />
                        </div>
                      </div>

                      <div className="flex items-center  mt-6">
                        <LockClosedIcon className="w-6 mr-3 flex-shrink-0" />
                        <p className="text-sm text-gray-500">
                          All information is completely protected. <br />
                          Click our
                          <a
                            className="text-blue-500"
                            target="_blank"
                            href="https://uptosheets.com/privacy.html"
                          >
                            Privacy Policy
                          </a>
                          for more guidance on data security concerns.
                        </p>
                      </div>

                      <div className="flex justify-end mt-20 px-4">
                        <Button
                          className="px-10"
                          onClick={() => setStep(1)}
                          disabled={isEmpty(user.google_account_email)}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}

                  {step === 1 && (
                    <>
                      <div className="bg-green-50 rounded-md md:flex items-end justify-between overflow-hidden">
                        <div className="max-w-xl  p-4">
                          <h4 className="text-lg font-medium">
                            Which spreadsheet do you want to use?
                          </h4>
                          <p className="text-sm text-gray-500 mt-1">
                            Make sure you are using a sheet created with an
                            account linked to UpToSheets. It does not work if
                            created with an account other than the linked
                            account
                          </p>
                        </div>
                        <img
                          className="translate-x-1 translate-y-1"
                          src={
                            require('../images/table-placeholder.svg').default
                          }
                          alt=""
                        />
                      </div>

                      <div className="mt-10">
                        <div>
                          <label
                            htmlFor="existing-sheet"
                            className="block font-medium text-gray-700"
                          >
                            Link to Existing Sheet
                          </label>

                          <div className="mt-2">
                            <input
                              type="text"
                              name="existing-sheet"
                              id="existing-sheet"
                              className="shadow-sm focus:ring-indigo-500 p-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Enter your spreadsheet link here"
                              onChange={(e) => setSheetID(e.target.value)}
                              value={sheetID}
                            />
                          </div>
                        </div>

                        <div className="bg-green-50 rounded-md p-4 md:p-6 inline-flex items-start mt-8">
                          <ExclamationCircleIcon className="text-green-500 w-8 flex-shrink-0" />
                          <div className="ml-4 md:ml-6">
                            <h4 className="font-medium">
                              Make sure The Connected Google Account have editor
                              permissions on that sheet:
                            </h4>

                            {/*<ul className="text-sm text-gray-500 mt-1">
                              <li>You can do this by:</li>
                              <li>1- Select the file you want to share.</li>
                              <li>2- Click Share on the top right.</li>
                              <li>
                                3- Click the drop-down arrow under Get Link.
                              </li>
                              <li>
                                4- Choose to share the file with Anyone with the
                                link.
                              </li>
                              <li>5- Set permission as Editor.</li>
                              <li>6- Click Copy link.</li>
                            </ul>*/}
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between mt-20 px-4">
                        <Button
                          variant="outline"
                          className="px-10"
                          onClick={() => setStep(0)}
                        >
                          Back
                        </Button>
                        <Button
                          className="px-10"
                          onClick={sheetIDHandler}
                          disabled={!sheetID}
                          loading={loading}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <h2 className="text-3xl font-medium">
                        Select your Spreadsheet Tab
                      </h2>
                      <div className="mt-6 space-y-4">
                        <h4 className="text-lg font-medium">
                          Which tab you want to use
                        </h4>
                        {/* Radio groups */}
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            Pricing spreadsheetTabs
                          </RadioGroup.Label>
                          <div className="relative bg-white rounded-md space-y-3">
                            {sheetTabs.map((tab) => (
                              <RadioGroup.Option
                                key={tab}
                                value={tab}
                                className={({ checked }) =>
                                  classNames(
                                    'border border-gray-200 rounded-md',
                                    checked
                                      ? 'bg-green-50 border-green-300 z-10'
                                      : 'border-gray-200',
                                    'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex items-center text-sm">
                                      <span
                                        className={classNames(
                                          checked
                                            ? 'bg-green-500 border-transparent'
                                            : 'bg-white border-gray-300',
                                          active
                                            ? 'ring-2 ring-offset-2 ring-green-500'
                                            : '',
                                          'h-4 w-4 rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? 'text-green-900'
                                            : 'text-gray-900',
                                          'ml-4 font-medium'
                                        )}
                                      >
                                        {tab}
                                      </RadioGroup.Label>
                                    </div>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>

                        <div className="text-center">
                          <button
                            className="text-blue-500 hover:text-blue-600 font-medium text-sm"
                            type="button"
                            onClick={refreshTabListHandler}
                          >
                            Refresh List
                          </button>
                        </div>

                        <div className="h-px bg-gray-200"></div>

                        <div className="bg-green-50 rounded-md p-4 md:p-6 inline-flex items-start mt-8">
                          <ExclamationCircleIcon className="text-green-500 w-8 flex-shrink-0" />
                          <div className="ml-4 md:ml-6">
                            <h4 className="font-medium">
                              Make sure your spreadsheets column has titles
                              before you go to last step
                            </h4>
                            <p className="text-sm text-gray-500 mt-1">
                              See how you can name your sheets header &nbsp;
                              &nbsp;
                              <a
                                href="/link"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:text-blue-600"
                              >
                                https://www.wikihow.com/Make-a-Header-on-Google-Sheets-on-PC-or-Mac
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between mt-20 px-4">
                        <Button
                          variant="outline"
                          className="px-10"
                          onClick={() => setStep(1)}
                        >
                          Back
                        </Button>
                        <Button
                          className="px-10"
                          onClick={sheetTabsHandler}
                          loading={loading}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}

                  {step === 3 && (
                    <>
                      <h2 className="text-lg font-medium">
                        Map fields from Shopify into Google Sheets
                      </h2>

                      <Notice className="mt-6">
                        <p className="text-sm">
                          <a
                            href="/link"
                            className="text-blue-500 hover:text-blue-600"
                          >
                            (Notice : Order ID is obligatory)
                          </a>
                          &nbsp; &nbsp; Enter a value on the first row with data
                          points you need and customize specific settings based
                          on your store reporting method
                        </p>
                      </Notice>

                      <div className="mt-8 space-y-6">
                        {columns.length &&
                          columns.map((column, index) => (
                            <div key={column.name}>
                              <label
                                htmlFor={column.name}
                                className="block font-medium text-gray-700"
                              >
                                {column.name}
                              </label>
                              <select
                                id={column.name}
                                className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                {labels &&
                                  labels.map((label) => (
                                    <option
                                      value={label.value}
                                      key={label.value}
                                    >
                                      {label.label}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          ))}

                        <div className="flex justify-center">
                          <button
                            type="button"
                            className="text-blue-500 hover:text-blue-600 font-medium"
                            onClick={refreshSelectListHandler}
                          >
                            Refresh List
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-between mt-20 px-4">
                        <Button
                          variant="outline"
                          className="px-10"
                          onClick={() => setStep(2)}
                        >
                          Back
                        </Button>
                        <Button
                          className="px-10"
                          onClick={finishHandler}
                          loading={loading}
                        >
                          Finish
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Finish page */}
          {page === 'finish' && (
            <div className="card">
              <h2 className="text-3xl font-medium text-center">
                You all set 🎉
              </h2>
              <img
                className="mx-auto mt-6"
                src={require('../images/shopify-sheets.svg').default}
                alt=""
              />

              <div className="max-w-xl mx-auto mt-5">
                <div className="bg-green-50 rounded-md flex items-center px-6 py-4">
                  <CheckCircleIcon className="text-green-500 w-10 h-10 flex-shrink-0 mr-6" />
                  <p className="">
                    Your Shopify stores orders are successfully <br /> connected
                    to your spreadsheet
                  </p>
                </div>
              </div>
              <div className="h-px bg-gray-200 my-8"></div>
              <div className="mx-auto max-w-xl text-center">
                <h4 className="flex justify-center text-xl font-medium">
                  <img
                    className="flex-shrink-0 w-8 h-8 mr-2"
                    src={require('../images/back-in-time-icon.png')}
                    alt=""
                  />
                  Back in time
                </h4>
                <p className="text-gray-500 mt-4">
                  By executing the ‘Back-In-Time’ feature you will be able to
                  add past fulfilled orders to you sheet. To execute, choose a
                  start date and click “Sync”
                </p>

                <div className="mt-4">
                  <DatePick startDate={startDate} setStartDate={setStartDate} />
                </div>

                <div className="flex justify-between mt-5">
                  <Button
                    className="md:min-w-[180px]"
                    variant="outline"
                    onClick={() => navigate('/')}
                  >
                    Later
                  </Button>
                  <Button
                    className="md:min-w-[180px]"
                    onClick={syncOrdersHandler}
                  >
                    Sync Orders now
                  </Button>
                </div>

                <p className="mx-auto max-w-sm mt-4 text-gray-500 text-sm">
                  This feature will always be available at your command in the
                  navigation bar if you choose to skip it for now
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Alert show={showAlert} type={typeAlert}>
        {textAlert}
      </Alert>
    </>
  );
}

export default OnBoarding;
